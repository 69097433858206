import React from "react";

const About = () => (
  <>
    <div>
      <section className="page-header">
        <div
          className="page-header__bg"
          style={{
            backgroundImage: 'url("assets/images/aboutupdated.jpg")',
          }}
        />
        {/* /.page-header__bg */}
        <div className="container">
          <h2 className="page-header__title"></h2>

          {/* /.thm-breadcrumb list-unstyled */}
        </div>
        {/* /.container */}
      </section>
      {/* /.page-header */}
      <section className="about-one about-one--page">
        <div
          className="about-one__shape-four"
          style={{
            backgroundImage: "url(/assets/images/shapes/about-1-shape-4.png)",
          }}
        />
        <div className="container">
          <div className="row">
            <div className="col-lg-6 wow fadeInLeft" data-wow-delay="100ms">
              <div className="about-one__content">
                <div className="sec-title text-left">
                  <h6 className="sec-title__tagline">About Us</h6>
                  {/* /.sec-title__tagline */}
                  {/* <h3 className="sec-title__title">
                    Unlock Your Digital Potential with cloud digital solution
                  </h3> */}
                  {/* /.sec-title__title */}
                </div>
                {/* /.sec-title */}
                <p className="about-one__content__text">
                  <h1>
                    Unlock Your Digital Potential with Cloud Digital Solution
                  </h1>
                  <p >
                    Since 2021, Cloud Digital Solution has been a trusted
                    partner for businesses in Pune, India, and beyond. With 10
                    years of expertise team, we empower your brand to thrive in
                    the digital realm through:<br/>
                     GST NO: 27AAUFC6408L1ZV
                  </p>

                  <div className="services">
                    <h2>Our Services</h2>
                    <ul>
                      <li>
                        <strong>Digital Marketing:</strong> Reach and engage
                        your target audience
                      </li>
                      <li>
                        <strong>Website Development:</strong> Craft a stunning
                        online presence
                      </li>
                      <li>
                        <strong>Mobile App Development:</strong> Unlock new
                        possibilities with mobile solutions
                      </li>
                      <li>
                        <strong>Website Maintenance:</strong> Ensure seamless
                        performance and security
                      </li>
                      <li>
                        <strong>Graphic Design:</strong> Visualize your brand's
                        story and values
                      </li>
                    </ul>
                  </div>

                  <h2>Embark on a Transformative Journey</h2>
                  <p>
                    At Cloud Digital Solution, we believe in more than just
                    services — we offer an experience. Together, let's blend
                    creativity, technology, and strategy to craft a unique
                    digital narrative that propels your brand forward. Join us
                    in a partnership that exceeds expectations, guiding your
                    brand toward digital success.
                  </p>
                </p>
               
            
              </div>
              {/* /.why-choose-two__content */}
            </div>
            {/* /.col-lg-6 */}
            <div className="col-lg-6">
              <div
                className="about-one__image wow fadeInRight"
                data-wow-delay="200ms"
              >
                <div
                  className="about-one__image__bg"
                  style={{
                    backgroundImage:
                      "url(/assets/images/shapes/about-image-bg.png)",
                  }}
                />
                <img
                  src="/assets/images/resources/about-1-1.png"
                  alt="growim"
                />
                <div
                  className="about-one__image__clients wow fadeInUp"
                  data-wow-delay="400ms"
                >
        
                  <span>100 + Happy Clients</span>
                </div>
              </div>
              {/* /.why-choose-two__image */}
            </div>
            {/* /.col-lg-6 */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>
      {/* /.about-one */}
      <section
        className="work-process-two"
        style={{
          backgroundImage:
            "url(/assets/images/shapes/work-process-two-bg-shape.png)",
        }}
      >
        <div className="container">
          <div className="work-process-two__top">
            <div className="sec-title text-center">
              <h6 className="sec-title__tagline">Work Process</h6>
              {/* /.sec-title__tagline */}
              <h3 className="sec-title__title">Our Working Pocess</h3>
              {/* /.sec-title__title */}
            </div>
            {/* /.sec-title */}
          </div>
          <div className="row gutter-y-30">
            <div
              className="col-lg-3 col-md-6 wow fadeInUp"
              data-wow-delay="00ms"
            >
              <div className="work-process-two__item">
                <div className="work-process-two__item__icon">
                  <i className="flaticon-service" />
                  <div className="work-process-two__item__number" />
                </div>
                <h3 className="work-process-two__item__title">
                  Choose A Service
                </h3>
                <p className="work-process-two__item__text">
                  Explore our range of services and select the one that best
                  aligns with your needs. Whether you require expert
                  consultation or end-to-end solutions, our team is here to
                  guide you at every step.
                </p>
              </div>
            </div>
            {/* item */}
            <div
              className="col-lg-3 col-md-6 wow fadeInUp"
              data-wow-delay="100ms"
            >
              <div className="work-process-two__item work-process-two__item--reverse">
                <h3 className="work-process-two__item__title">
                  Define Requirements
                </h3>
                <p className="work-process-two__item__text">
                  Collaborate with our experts to clearly outline your project’s
                  goals, expectations, and desired outcomes. This step ensures
                  we tailor our approach to deliver maximum value and
                  satisfaction.
                </p>
                <div className="work-process-two__item__icon">
                  <i className="flaticon-define" />
                  <div className="work-process-two__item__number" />
                </div>
              </div>
            </div>
            {/* item */}
            <div
              className="col-lg-3 col-md-6 wow fadeInUp"
              data-wow-delay="200ms"
            >
              <div className="work-process-two__item">
                <div className="work-process-two__item__icon">
                  <i className="flaticon-team" />
                  <div className="work-process-two__item__number" />
                </div>
                <h3 className="work-process-two__item__title">
                  Request A Meeting
                </h3>
                <p className="work-process-two__item__text">
                  Schedule a meeting with us to discuss your project in detail.
                  During this session, we’ll address your questions, provide
                  insights, and present a customized strategy for achieving your
                  objectives.
                </p>
              </div>
            </div>
            {/* item */}
            <div
              className="col-lg-3 col-md-6 wow fadeInUp"
              data-wow-delay="300ms"
            >
              <div className="work-process-two__item work-process-two__item--reverse">
                <h3 className="work-process-two__item__title">
                  Final Solution
                </h3>
                <p className="work-process-two__item__text">
                  We bring your vision to life with precision and dedication.
                  From concept to completion, we deliver a solution that meets
                  your requirements and exceeds your expectations.
                </p>
                <div className="work-process-two__item__icon">
                  <i className="flaticon-solution" />
                  <div className="work-process-two__item__number" />
                </div>
              </div>
            </div>
            {/* item */}
          </div>
          <div
            className="work-process-two__border wow fadeInUp"
            data-wow-delay="400ms"
            style={{
              backgroundImage:
                "url(/assets/images/shapes/work-process-two-border.png)",
            }}
          />
        </div>
      </section>
      {/* work-process-two */}
      <section className="why-choose-three why-choose-three--about-page">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-5 d-flex align-items-center wow fadeInLeft"
              data-wow-delay="100ms"
            >
              <div className="why-choose-three__content">
                <div className="sec-title text-left">
                  <h6 className="sec-title__tagline">why choose us</h6>
                  {/* /.sec-title__tagline */}
                  <h3 className="sec-title__title">Why Choose Us</h3>
                  {/* /.sec-title__title */}
                </div>
                {/* /.sec-title */}
                <p className="why-choose-three__content__text">
                  Transform your online presence with our expert digital
                  marketing services. We're a leading agency dedicated to
                  helping businesses thrive in the digital world.
                </p>
                <ul className="why-choose-three__list">
                  <li>
                    <div className="why-choose-three__list__icon">
                      <i className="flaticon-mission" />
                    </div>
                    Business
                    <br /> Growth
                  </li>
                  <li>
                    <div className="why-choose-three__list__icon">
                      <i className="flaticon-technology" />
                    </div>
                    Technology
                    <br /> Consultancy
                  </li>
                </ul>
                <div className="row gutter-y-30"></div>
              </div>
              {/* /.why-choose-three__content */}
            </div>
            {/* /.col-xl-6 */}
            <div className="col-lg-7">
              <div
                className="why-choose-three__image wow fadeInUp"
                data-wow-delay="100ms"
              >
                <div
                  className="why-choose-three__image__bg wow fadeInRight"
                  data-wow-delay="300ms"
                  style={{
                    backgroundImage:
                      "url(/assets/images/shapes/why-choose-3-bg.png)",
                  }}
                />
                <img
                  src="/assets/images/resources/why-choose-3-1.png"
                  alt="growim"
                />
              </div>
              {/* /.why-choose-three__image */}
            </div>
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>
      {/* /.why-choose-three */}
    </div>
  </>
);

export default About;
